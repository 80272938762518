import React from 'react'
import get from 'lodash/get'
import { Link } from 'gatsby'
import { isExternalUrlOrHash, contentfulMapToLocalized } from "../../helpers.js"
import Img from "./image"

class ImagesGrid extends React.Component {
  constructor(props) {
    super(props);
    const node = this.props.node
    const imagesGridElement = contentfulMapToLocalized(node.fields)
    this.images = imagesGridElement.images
  }
  render() {    
    return (
      <div className="images-grid-container d-flex flex-wrap justify-content-around">
        { this.images.map((image, index) => (
          <div className="col-12 col-sm-6 col-md-4 mt-3" key={index}>
            <Img node={image} />
          </div>
        )) }
      </div>
    )
  }
}

export default ImagesGrid
