import React from 'react'
import get from 'lodash/get'
import { Link } from 'gatsby'
import { isExternalUrlOrHash, contentfulMapToLocalized, createWidthHopsForImage } from "../../helpers.js"

class Image extends React.Component {
  constructor(props) {
    super(props);
    const node = this.props.node
    const maxWidth = 1000;
    const asset = contentfulMapToLocalized(node.fields)   
    const widths = createWidthHopsForImage(asset.file.details.image, 300, 200, maxWidth) //1200 is max width we want
    let sizes = [], srcSet = []
    const widthsLength = widths.length;
    widths.forEach((width, index) => {
      srcSet.push(`${asset.file.url}?w=${width}&fit=scale`)
      
      if (index === widthsLength - 1) {
        //We don't want to specify a `max-size` for the bigger size
        sizes.push(`${width}`)
      } else {
        sizes.push(`(max-width: ${width}) ${width}`)
      }
    })
    const originalWidth = get(asset.file.details.image, 'width', 1000)
    const originalHeight = get(asset.file.details.image, 'height', 1000)
    const newWidth = Math.min(originalWidth, maxWidth)
    const newHeght = (originalHeight / originalWidth) * newWidth
    this.imageAttrs = {
      sizes: sizes.join(', '),
      srcSet: srcSet.reverse().join(', '),
      src: `${asset.file.url}?fm=jpg&fl=progressive`,
      alt: asset.description || asset.title,
      className: 'img-fluid',
      loading: 'lazy',
      height: `${newHeght}`,
      width: `${newWidth}`,
    }
    this.containerClassName = `image-container ${this.props.className ? this.props.className : ''}`
  }
  render() {    
    return (
      <div className={ this.containerClassName }>
        <img { ...this.imageAttrs }/>
      </div>      
    )
  }
}

export default Image
